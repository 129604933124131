import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Link,
  Box,
  Image,
  Section,
  Strong,
  Icon,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdDone } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Наші послуги | Послуги в гольф-клубі Oakleaf Greens</title>
        <meta
          name={"description"}
          content={"Кожна послуга - штрих досконалості!"}
        />
        <meta
          property={"og:title"}
          content={"Наші послуги | Послуги в гольф-клубі Oakleaf Greens"}
        />
        <meta
          property={"og:description"}
          content={"Кожна послуга - штрих досконалості!"}
        />
        <meta
          property={"og:image"}
          content={"https://scanovater.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://scanovater.com/img/3570073.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://scanovater.com/img/3570073.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://scanovater.com/img/3570073.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://scanovater.com/img/3570073.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://scanovater.com/img/3570073.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://scanovater.com/img/3570073.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://scanovater.com/img/3570073.png"}
        />
      </Helmet>
      <Components.Eader />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Наші послуги
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            В Oakleaf Greens ми виходимо за рамки основ гольфу, пропонуючи
            комплексний набір послуг, покликаних покращити ваш досвід і
            підвищити рівень гри. Наші пропозиції охоплюють усі аспекти вашої
            гольф-подорожі, гарантуючи, що кожен візит - це більше, ніж просто
            гра, це повноцінний досвід.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Контакти
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://scanovater.com/img/7.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-8"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          grid-gap="5%"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          display="flex"
          width="35%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 24px 0px"
            font="--headline2"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="normal 600 40px/1.2 --fontFamily-sans"
            text-align="left"
            md-text-align="center"
            sm-text-align="left"
          >
           Наш світ гольфу
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--base"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            text-align="left"
            md-text-align="center"
            sm-text-align="left"
          >
            В Oakleaf Greens ми виходимо за рамки основ гольфу, пропонуючи комплексний набір послуг, покликаних покращити ваш досвід і підвищити рівень гри. Наші пропозиції охоплюють усі аспекти вашої гольф-подорожі, гарантуючи, що кожен візит - це більше, ніж просто гра, це повноцінний досвід.
          </Text>
        </Box>
        <Box
          width="60%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="48px 32px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          md-grid-gap="36px "
        >
		<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
		  <Icon
			display="block"
			category="md"
			icon={MdDone}
			size="20px"
			color="--primary"
			margin="2px 16px 0px 0px"
		  />
		  <Box display="flex" flex-direction="column">
			<Text
			  margin="0px 0px 0px 0px"
			  font="--base"
			  color="--dark"
			  width="100%"
			  md-width="100%"
			  text-align="left"
			>
			  <Strong>Центр примірки та тестування екіпіровки для гольфу</Strong>
			</Text>
			<Text
			  margin="12px 0px 0px 0px"
			  font="--base"
			  color="--dark"
			  width="100%"
			  md-width="100%"
			  text-align="left"
			  lg-margin="8px 0px 0px 0px"
			>
			  Індивідуальне припасування: Відрегулюйте ключки на основі аналізу
            вашого замаху.
            <br />
            <br />
            Відстеження ефективності: Використовуйте найсучасніші технології для
            моніторингу покращень та прийняття рішень на основі даних.
			</Text>
		  </Box>
		</Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Професійні інструкції з гольфу</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Приватні уроки: Індивідуальні тренування, адаптовані до ваших
                навичок та цілей.
                <br />
                <br />
                Групові клініки: Вчіться і розвивайтеся разом з іншими в
                атмосфері групової підтримки.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Чемпіонське поле для гольфу</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Кілька трійників: Підходить для всіх рівнів майстерності, від
                початківців до професіоналів.
                <br />
                <br />
                Фірмові лунки: Відчуйте виклик наших унікальних фірмових лунок,
                які вимагають як стратегії, так і навичок.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Заходи та турніри для членів клубу</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Щомісячні турніри: Беріть участь у різноманітних змагальних
                заходах.
                <br />
                <br />
                Соціальні мікстери: Приєднуйтесь до інших учасників, щоб
                розважитися та відпочити після раунду.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Молодіжні програми</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Дитячі табори: Сезонні табори, які навчають основам та етикету.
                <br />
                <br />
                Ліги розвитку: Для юнаків та дівчат, які прагнуть покращити свою
                гру в конкурентній боротьбі.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Здоров'я та гарне самопочуття</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Йога для гольфістів: Заняття, спрямовані на покращення гнучкості
                та ментальної концентрації.
                <br />
                <br />
                Фітнес-центр: Доступ до тренувального обладнання для гольфу та
                персональних тренерів.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://scanovater.com/img/8.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Зануртеся глибше в Oakleaf Greens
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Відкрийте для себе все, що може запропонувати Oakleaf Greens,
            зв'язавшись з нами або відвідавши нас особисто. Наші співробітники з
            радістю нададуть вам детальну інформацію та допоможуть вибрати
            послуги, які найкраще відповідають вашим потребам. Від
            гравців-початківців до досвідчених гольфістів, в Oakleaf Greens
            знайдеться щось для кожного. Приєднуйтесь до нас і збагатіть свій
            досвід гри в гольф!
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
